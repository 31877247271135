// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const PROTECTED_DIRECTORY_LIST_DEFAULT_INPUT = {
    pagination: {
        page: 1,
        itemsPerPage: 25,
    },
    searchFilters: {
        path: '',
    },
    sort: {
        sortColumn: null,
        sortDirection: null,
    },
};
